<template>
    <modal ref="modalEditarGrupoActividades" titulo="Editar grupo de actividades" adicional="Guardar" @adicional="crearGrupo">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-12 my-2">
                <p class="input-label-top">Nombre del grupo</p>
                <el-input v-model="nombreGrupo" class="w-100" size="small" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            nombreGrupo: '',
        }
    },
    methods: {
        toggle(name){
            this.nombreGrupo = name
            this.$refs.modalEditarGrupoActividades.toggle()
        },
        crearGrupo(){
            this.toggle()
        }
    }
}
</script>